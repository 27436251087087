.btn-primary {
    background: $primary !important;
    color: rgba(255, 255, 255, 1) !important;
    border-radius: $btnRadius !important;
    border: none !important;
    font-size: 14.54px !important;
    font-weight: 400 !important;
    // line-height: 13.73px !important;
    text-align: left !important;
    padding: .6rem 1rem !important;

}

.btn-danger {
    background: $primary !important;
}

.btn {
    transition: transform 0.3s !important;

    &:hover{
        transform: scale(1.1);
    }
}