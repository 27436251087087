.categories {
  display: flex;
  justify-content: flex-start;
  padding: 2rem;
  margin-top: 1rem;

  .sideBar {
    background: linear-gradient(92deg, #214293 -18.91%, #ec2127 149.59%);
    flex-shrink: 0;
    width: 250px;
    height: 100vh;
    //  height: 100%;
    border-radius: 25px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

    .cursor-pointer {
      cursor: pointer;
    }

    .text-decoration {
      text-decoration: none;
    }

    .capitalize {
      text-transform: capitalize;
    }

    .pb-6 {
      padding-bottom: 2rem;
    }

    .m-6 {
      margin: 4rem;
    }

    ul {
      list-style: none;
      padding: 3rem;
      padding-top: 2rem;
      padding-right: 0;
      padding-bottom: 0;

      li {
        cursor: pointer;
        padding: 10px 15px;
        color: white;
        font-size: 15px;
        margin-bottom: 0.5rem;
        transition: background 0.3s, transform 0.3s;

        &:active,
        &:hover {
          // background: rgba(255, 255, 255, 0.2);
          // background: rgba(255, 255, 255, 0.902);
          // background: transparent;
          // color: black;
          // color: #fff;
          transform: translateX(10px);
          font-weight: bold;
          transform: scale(1.1);
          -webkit-transform: translateX(10px);
          -moz-transform: translateX(10px);
          -ms-transform: translateX(10px);
          -o-transform: translateX(10px);
        }

        border-radius: 14px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  }
}
@media screen and (min-width: 200px) and (max-width: 600px) {
  .categories {
    padding: 0rem;
  }
}

.sideBar-active {
  background: rgba(255, 255, 255, 0.902);
  color: black !important;
  transform: translateX(10px);
  font-weight: bold;
  transform: scale(1.1);
  -webkit-transform: translateX(10px);
  -moz-transform: translateX(10px);
  -ms-transform: translateX(10px);
  -o-transform: translateX(10px);
}

.sideBar-active1 {
  background: rgba(255, 255, 255, 0.902);
  // padding: 5px 5px;
  width: 100%;
  display: flex;
  color: black !important;
  border-radius: 14px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  font-weight: bold;
}

.sideBar-active1 li {
  background: linear-gradient(92deg, #214293 -18.91%, #ec2127 149.59%);

  -webkit-background-clip: text; /* Safari and Chrome */
  -webkit-text-fill-color: transparent;
  clip-path: inset(0);

  &:hover {
    color: black !important;
  }
}

.categoriesCustomList {
  display: flex;
  flex-shrink: 1;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding-left: 4rem;
  gap: 0rem 2rem;
  // overflow-y: scroll;
  // max-height:100vh;
  // -ms-overflow-style: none !important;
  scrollbar-width: none !important;
  align-content: flex-start;

  .card {
    //  margin-right:auto;
    border: none;
    width: 216px !important;
    // height: 115px !important;
    cursor: pointer;
    img {
      border-radius: 5px;
      height: 140px !important;
      width: 216px !important;
      object-fit: cover;
    }
    img:hover {
      transition: 0.3s, transform 0.3s;
      transform: scale(1.1);
    }
  }

  @media screen and (min-width: 200px) and (max-width: 480px) {
    justify-content: center;
  }
}

@media screen and (min-width: 200px) and (max-width: 600px) {
  .categoriesCustomList {
    padding-left: 0rem;
    .card {
      width: 154px !important;
      img {
        border-radius: 5px;
        height: 100px !important;
        width: 154px !important;
        object-fit: cover;
      }
    }
  }
}

/* Styling for the Out of Stock banner */
.outOfStockBannerCategories {
  position: absolute;
  width: max-content;
  top: 35%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-45deg);
  background-color: #cc0000;
  color: white;
  padding: 0.3rem 1rem;
  font-size: 1em;
  text-align: center;
  border-radius: 5px;
  z-index: 10;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  // cursor: not-allowed; disabled
}

// Graying out the card
.outOfStockCard {
  // pointer-events: grab !important;
  position: relative;
  cursor: not-allowed;

  &_image {
    opacity: 0.6;
    filter: grayscale(100%);
    // cursor: not-allowed; disabled
  }
}

.cursor-disabled {
  cursor: not-allowed;
}
