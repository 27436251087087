 
$bg-muted:#b4b0b0; 
$linearRightBg:linear-gradient(92deg, #214293 -18.91%, #a1191d 149.59%);
$linearLeftBg:linear-gradient(92deg, #942f32 -10.91%, #214293 120.59%);
$linkBorder:#75095e;
$mobileNav:linear-gradient(334deg, #214293 -18.91%, #663240 109.59%, #ad57b2 149.59%, #3c0e3e 149.59%);
$btnNavLogBg:linear-gradient(92deg, #ec2127 -18.91%, #214293 149.59%);
$primary-color:rgba(236, 32, 39, 1);
$btnRadius:8px;
$secondary:rgba(33, 65, 146, 1);
$primary:rgb(218, 10, 17);