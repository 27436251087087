.muted-bg {
  background: $bg-muted !important;
}
.activeLink {
  color: $linkBorder !important;
}

.activeLinkTwo {
  color: black !important;
}

.btnNavLogBg {
  background: $btnNavLogBg;
  color: white !important;
  border: none;
}

.primary-bg {
  background: $primary !important;
}
