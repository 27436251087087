@import url("https://fonts.googleapis.com/css2?family=Epilogue:ital,wght@0,100..900;1,100..900&display=swap");

.checkOutPage {
  // width: 70rem;
  width: 80%;
  margin: auto;
  padding: 1.5rem 4rem 1.5rem 1.5rem;

  .orderLists {
    h3 {
      color: #000000b3;
      font-family: Poppins;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      display: inline-block;
    }
  }

  .orderCards {
    border: 1px solid #dedede;
    margin-bottom: 1.5rem;
    padding: 0.7rem 0.6rem;

    img {
      border-radius: 104px;
      width: 60px !important;
      height: 60px !important;
      border: 2px solid #dedede;
      object-fit: cover;
    }

    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 0.3rem;
    -webkit-border-radius: 0.3rem;
    -moz-border-radius: 0.3rem;
    -ms-border-radius: 0.3rem;
    -o-border-radius: 0.3rem;

    .imageCard {
      width: 40px;
    }
  }

  .cartProductInfo {
    width: 50%;
    p {
      color: #000000b3;
      font-family: Poppins;
      font-size: 16px;
      font-style: itallic;
      font-weight: 500;
      line-height: normal;
      display: inline-block;
    }
  }

  .cartProductQuantity {
    display: flex;
    justify-content: space-between;

    div {
      margin-left: 20px;
    }

    label {
      font-size: 12px !important;
      font-weight: bold;
    }

    input {
      width: 58px !important;
      height: 30px !important;
      padding: 0 !important;
      text-align: center;
      border-radius: 0.2rem !important;
    }
    .quantity-input-container {
      position: relative;
      display: flex;
      align-items: center;

      .quantity-input {
        width: 100%;
        text-align: center;
        padding: 8px 32px; // Leaves room for the arrows
        border: 1px solid #ccc;
        border-radius: 4px;
        outline: none;
        font-size: 16px;
      }

      .arrow-buttons {
        position: absolute;
        top: 50%;
        right: 8px;
        transform: translateY(-50%);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 0px;

        .arrow {
          cursor: pointer;
          font-size: 8px;
          color: #555;
          transition: color 0.2s;

          &:hover {
            color: #000;
          }

          &.disabled {
            cursor: not-allowed;
            opacity: 0.5;
          }
        }
      }
    }

    // input[type=number]::-webkit-outer-spin-button,
    // input[type=number]::-webkit-inner-spin-button {
    //   -webkit-appearance: auto;
    //   margin: 0;
    // }

    // input[type=number] {
    //   -moz-appearance: textfield;
    // }
    // .netPriceBox{
    // width: 120px !important;
    //   input {
    //       width: 100px !important;
    //       }
    // }
  }

  .orderSummary {
    color: white;
    width: 369px;
    margin-top: 4rem;

    ul {
      li {
        list-style-type: none;
      }
    }

    h3 {
      color: #fff;
      font-family: Poppins;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      text-align: center;
    }

    .topOrder {
      background: #b4b0b0;
      opacity: 90%;
      backdrop-filter: blur(10px);
      border-radius: 0.2rem 0.2rem 0 0 !important;
      -webkit-border-radius: 0.2rem 0.2rem 0 0 !important;
      -moz-border-radius: 0.2rem 0.2rem 0 0 !important;
      -ms-border-radius: 0.2rem 0.2rem 0 0 !important;
      -o-border-radius: 0.2rem 0.2rem 0 0 !important;
      padding: 2rem;

      input {
        background: transparent;
        color: white;
        width: 100%;
        height: 53px;
        padding: 0 0.6rem;

        &::placeholder {
          color: white;
        }
      }

      .couponCode {
        .btn {
          border-radius: 0.3rem !important;
          -webkit-border-radius: 0.3rem !important;
          position: absolute;
          right: 2.5rem;
          margin-top: -2.9rem;
          -moz-border-radius: 0.3rem !important;
          -ms-border-radius: 0.3rem !important;
          -o-border-radius: 0.3rem !important;
        }
      }

      ul {
        padding: 0;

        li {
          display: flex;
          justify-content: space-between;
          margin-top: 1rem;
          border-bottom: 1px solid #ffffff1a;
          padding: 1rem 0;

          &:last-child {
            border-bottom: none;
            margin-top: 0;
          }
        }
      }
    }
    .customBorder {
      border-radius: 1rem;
      -webkit-border-radius: 1rem;
      -moz-border-radius: 1rem;
      -ms-border-radius: 1rem;
      -o-border-radius: 1rem;
    }
    .infoOrderText {
      background: #37474f;
      padding: 2rem;
      border-radius: 0 0 0.2rem 0.2rem !important;
      -webkit-border-radius: 0 0 0.2rem 0.2rem !important;
      -moz-border-radius: 0 0 0.2rem 0.2rem !important;
      -ms-border-radius: 0 0 0.2rem 0.2rem !important;
      -o-border-radius: 0 0 0.2rem 0.2rem !important;
      color: #fffc;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      // font-weight: 500;
      // line-height: normal;
    }
  }

  .proceedPay {
    .btn {
      margin-top: -5rem;
      position: relative;
    }
  }

  .form-check {
    margin-top: 1rem;
    display: flex;
    align-items: center;
  }

  .form-check-input {
    margin-right: 0.5rem;
    accent-color: rgba(255, 255, 255, 0.6);
    /* This sets the color of the checkbox */
    background-color: rgba(255, 255, 255, 0.2);
    border: 1px solid rgba(255, 255, 255, 0.3);
  }

  .proceedLink {
    color: #ec2027;
    text-decoration: none;
  }
}

@media screen and (min-width: 200px) and (max-width: 1200px) {
  .checkOutPage {
    display: flex;
    justify-content: center;
    padding: 1.5rem !important;
  }

  .selectPayment {
    width: 100% !important;
  }
}

.cartDropDown {
  display: inline;
  position: absolute;
  right: 2rem !important;
  margin-top: 1rem !important;
  width: 330px !important;
  height: auto !important;
  background-color: #fff;
  border-radius: 5px;
  padding: 0 1rem;
  z-index: 99;
}

.selectPayment {
  width: 80%;
  margin: 4rem auto;
  padding: 1.5rem;

  .modal-content {
    .modal-body {
      padding: 1rem 2rem;
    }

    .btn-Close {
      color: whitesmoke !important;
      border: 1px solid whitesmoke;
      &:hover {
        background: $primary;
        border-color: $primary;
      }
      border-radius: 48%;
    }

    .form-group {
      label {
        font-size: 13px;
        margin-bottom: 0.3rem;
      }

      margin-bottom: 1.5rem;
    }

    background: #37474f;
    color: whitesmoke;

    // input {
    //     // background:transparent !important;
    // }
  }

  .cryptoDex1 {
    background: #37474f;
    color: white;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-top: 1rem;
    padding: 1rem;
    gap: 2px;

    border-radius: 0.3rem;
    -webkit-border-radius: 0.3rem;
    -moz-border-radius: 0.3rem;
    -ms-border-radius: 0.3rem;
    -o-border-radius: 0.3rem;

    img {
      width: 200px !important;
    }

    .cursor-pointer {
      cursor: pointer;
    }
  }

  .cryptoDex {
    background: #37474f;
    color: white;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-top: 1rem;
    padding: 1rem;
    gap: 2px;

    img {
      width: 45px !important;
    }

    .listBoard {
      margin: 0.5rem;
      width: 12rem;

      span {
        margin-left: 0.6rem;
      }
    }

    border-radius: 0.3rem;
    -webkit-border-radius: 0.3rem;
    -moz-border-radius: 0.3rem;
    -ms-border-radius: 0.3rem;
    -o-border-radius: 0.3rem;
  }
}

.ReviewForm {
  text-align: center;
  .starsBox {
    display: flex;
    justify-content: center;
    span {
      margin: 0 0.4rem;
    }
  }
  p {
    color: #e6e6e6;
    text-align: center;
    opacity: 0.8;
    font-family: Inter;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 140.5%;
  }
}
.ProductOrder {
  margin-bottom: 2rem;

  .productDetailed {
    background: #1a46b5;
    border-radius: 10px;
    flex-shrink: 0;
    width: 378.018px;
    height: 186px;
    margin: 1rem auto;
    // margin-bottom:0;
    padding: 1rem;
    img {
      width: 150px !important;
    }
    .headText {
      display: flex;
      justify-content: space-between;
      margin-bottom: 3rem;
    }
    .pinBox {
      padding: 0.2rem 0.5rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: #fff;
      color: #000;
      border-radius: 0.3rem;
      margin-top: 1rem;
      .pin {
        color: #787878;
      }
    }
  }
}
.orderCompleted {
  border: 1px solid #ddd;
  width: 100%;
  max-width: 600px;
  background: #f5f7fa;
  margin: 20px auto;
  padding: 20px;
  border-radius: 0.3rem;
  font-family: "Epilogue", "Open Sans", sans-serif !important;
  * {
    font-family: "Epilogue", "Open Sans", sans-serif !important;
  }
  .order-success {
    text-align: center;
    .hText {
      text-align: center;
      font-family: Poppins;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      display: block;
      margin-top: 3rem;
      margin-bottom: 1rem;
    }
    p {
      text-align: center;
      opacity: 0.8;
      font-family: Inter;
      font-size: 17px;
      font-style: normal;
      font-weight: 400;
      line-height: 140.5%;
    }

    img {
      width: 78px !important;
    }
  }
}

.imgConverterRate {
  img {
    width: 18px !important;
  }
}
.p-dropdown-items {
  padding: 0 !important;
}
.p-dropdown {
  padding: 0.4rem 0;
  border: 1px solid whitesmoke !important;
}

.outOfStock {
  max-width: 500px;
  // position:absolute;
  border: 1px solid grey;
  padding: 1rem;
  border-radius: 0.4rem;
}

.outOfStockNotification {
  max-width: 500px;
  // position:absolute;
  border: 1px solid grey;
  padding: 1rem;
  border-radius: 0.4rem;
}

.fh5co-loader {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(255, 255, 255, 0.9);
  z-index: 999;
  transition: all 0.5s ease;

  .loader {
    display: block;
    position: relative;
    left: 50%;
    top: 50%;
    width: 150px;
    height: 150px;
    margin: -75px 0 0 -75px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: #56d3ff;
    animation: spin 1s linear infinite;

    &:before {
      content: "";
      position: absolute;
      top: 5px;
      left: 5px;
      right: 5px;
      bottom: 5px;
      border-radius: 50%;
      border: 3px solid transparent;
      border-top-color: #56d3ff;
      animation: spin 1s linear infinite;
    }

    &:after {
      content: "";
      position: absolute;
      top: 15px;
      left: 15px;
      right: 15px;
      bottom: 15px;
      border-radius: 50%;
      border: 3px solid transparent;
      border-top-color: #56d3ff;
      animation: spin 1.5s linear infinite;
    }
  }
}

@keyframes spin {
  30% {
    transform: scale(1);
  }
  40%,
  60% {
    transform: rotate(-20deg) scale(1);
  }
  50% {
    transform: rotate(20deg) scale(1);
  }
  70% {
    transform: rotate(0deg) scale(1);
  }
  100% {
    transform: scale(1);
  }
}
