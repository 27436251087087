.zoom {
  position: relative;
  transition: all 0.3s !important;
  &:hover {
    z-index: 10000;
    transform: scale(1.1);
  }
}

.zoom1 {
  position: relative;
  transition: all 0.3s !important;
  &:hover {
    z-index: 10000;
    transform: scalex(1.01);
    -webkit-transform: scalex(1.01);
    -moz-transform: scalex(1.01);
    -ms-transform: scalex(1.01);
    -o-transform: scalex(1.01);
  }
}
