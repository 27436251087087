.loadingOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.9); // Semi-transparent background
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.wavingText {
  display: flex;
  span {
    font-size: 3rem;
    animation: wave 1.5s infinite;
    display: inline-block;
    margin: 0 0.1rem;
    // font-weight:bold;
    font-family: Poppins;
    // font-style:italic;
    background: linear-gradient(-9deg, #224191, #ec2326);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  img {
    width: 50px !important;
  }

  @for $i from 1 through 10 {
    span:nth-child(#{$i}) {
      animation-delay: calc(0.1s * #{$i - 1});
    }
  }
}

@keyframes wave {
  0%,
  60%,
  100% {
    transform: translateY(0);
  }
  30% {
    transform: translateY(-10px);
  }
}

.sidebarSkeleton {
  min-width: 15rem !important;
  min-height: 40rem !important;
  border-radius: 0.4rem;
}
